import { defineComponent as _defineComponent } from 'vue'
import useGlobal from '@/composables/useGlobal'
import { ref, onMounted, reactive, watch } from 'vue'
import { useShowModal } from '@/composables/useModalOpener'
import { useErrorsGetter, useParsedSetter } from '@/components/Forms/FormMixin'
// import InfoModal from '@components/Modals/InfoModal'
// import Confirm from '@components/Modals/Confirm'
// import { getCommonParsedErrors } from '@mixins/SendMixin'
import {
  startTestResource,
  finishTestResource,
  testOfUserResource,
  questionsOfTestResource,
} from '@services/tests.service'


export default /*@__PURE__*/_defineComponent({
  __name: 'TestController',
  props: {
  testId: {
    type: String,
    required: true
  },
  testTitle: {
    type: String,
    default: ""
  },
  testDescription: {
    type: String,
    default: ""
  },
  eventId: {
    type: String,
    required: true
  },
  fileUrl: {
    type: String,
    default: ""
  },
  userHasEvent: {
    type: Boolean,
    default: false
  }
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()
const isLoad = ref(false)
const result = ref('')
const questions = ref<any[]>([])
const questionsWithAnswers = ref<{ question: string; answer: any }[]>([])
const answersCount = ref(0)
const idTestOfUser = ref('')
const statusOfTest = ref('')
const test = ref('')
const formdata = reactive({
  answer: null,
})
const numberOfQuestion = ref(0)
const countOfQuestions = ref(0)
const currentQuestion = ref<{ question: any }[]>([])
const nextBtnText = ref('')
const testIsFinished = ref(false)
const testIsPassed = ref(false)
const testResultText = ref('')
const certFileUrl = ref('')

const getTestOfUser = async () => {
  isLoad.value = true
  const params = { pk: props.testId }

  // console.log('getTestOfUser')

  try {
    await testOfUserResource.execute(
      params
    ).then((res) => {
      const { data } = res

      if (data?.item) {
        idTestOfUser.value = data.item.id
        statusOfTest.value = data.item.status
        test.value = data.item
      }
    }).catch(async error => {
      console.log('error', error)
      // const parsed = await getCommonParsedErrors(error)

      // if (parsed?.id) {
      //   const [{ message }] = parsed.id
      //   showModal('Увага', message, null, window.testsPageLink)
      // }
    })
    

      // if (statusOfTest.value === 'unavailable') {
      //   showModal('Увага', 'Тест не доступен або застарів', 'До списку тестів', window.testsPageLink)
      // } else if (statusOfTest.value !== 'progress') {
      //   await openModalPromise()
      // }
  } finally {
    isLoad.value = false
  }
  // console.log('idTestOfUser', idTestOfUser)
  // console.log('test', test)
}

const getQuestions = async () => {
  // console.log('getQuestions')
  // control.setFieldError('nonFieldErrors', '')
  isLoad.value = true
  const params = { pk: props.testId }

  try {
    await questionsOfTestResource.execute(
      params
    ).then((res) => {
      const { data } = res
      if (data?.items) {
        questions.value = data.items
        countOfQuestions.value = questions.value.length
        questionsWithAnswers.value = questions.value.map(question => ({
          question: question.id,
          answer: null
        }))
      }
    }).catch(async (e: object) => {
    const parsed = await useErrorsGetter(e)
    // useParsedSetter(parsed, control.setFieldError)

    })
    
  } finally {
    isLoad.value = false
  }
  // console.log('questions', questions.value)
  // console.log('questionsWithAnswers', questionsWithAnswers.value)
}

const startTest = async () => {
  // console.log('startTest')
  isLoad.value = true
  // const params = {
  //   idOfTest: idTestOfUser.value,
  //   answers: questionsWithAnswers.value
  // }

  try {
    await startTestResource
    .execute(
      {},
      {
        id: props.testId,
        testOfUserId: idTestOfUser.value
      }
    )
    .then((res) => {
      const { data } = res
      idTestOfUser.value = data.item.id
      statusOfTest.value = 'progress'
      numberOfQuestion.value = 1
      certFileUrl.value = ""
      // console.log('numberOfQuestion', numberOfQuestion.value)
      nextBtnText.value = $t("Наступне питання")
      testIsFinished.value = false
    })
    .catch(async error => {
      console.log('error', error)
      // const parsed = await getCommonParsedErrors(error)

      // if (parsed?.id) {
      //   const [{ message }] = parsed.id
      //   showModal('Увага', message, null, window.testsPageLink)
      // }
    })
  } finally {
    isLoad.value = false
  }
}

const finishTest = async () => {
  // console.log('finishTest')
  isLoad.value = true
  const params = {
    idOfTest: idTestOfUser.value,
    answers: questionsWithAnswers.value
  }

  try {
    const res = await finishTestResource.execute(
      {},
      params
    ).then((res) => {
      const { data } = res
      if (data?.item) {
        const { item } = data
        const informationList: string[] = []
        testIsFinished.value = true

        if (item.status === 'failed') {
          testIsPassed.value = false
          // informationList.push('Тест не пройдено', 'Дякуємо за спробу!')
        } else {
          testIsPassed.value = true
          certFileUrl.value = item.certificateFileUrl
          // informationList.push('Тест пройдено', 'Вітаємо!')
        }

        testResultText.value = `Ви отримали ${item.rightQuestions} з ${parseInt(item.countQuestions, 10)} балів!`

        // informationList.push(`
        //   Тест здано на ${item.percentRightQuestions}%.,
        //   Вірно ${item.rightQuestions} відповідей із ${parseInt(item.countQuestions, 10)}
        // `)
        // console.log('informationList', informationList)
        // showModal('Результати тестування', informationList.join('\n'), 'Продовжити', window.testsResultsPageLink)
      }
    }).catch(async error => {
      console.log('error', error)
      // const parsed = await getCommonParsedErrors(error)

      // if (parsed?.id) {
      //   const [{ message }] = parsed.id
      //   showModal('Увага', message, null, window.testsPageLink)
      // }
    })
    
  } finally {
    isLoad.value = false
  }
}

const addAnswer = (questionIndex: number, answerId: any) => {
  // console.log('questionIndex', questionIndex)
  // console.log('addAnswer')
  if (!questionsWithAnswers.value[questionIndex].answer) {
    answersCount.value++
  }
  questionsWithAnswers.value[questionIndex].answer = answerId
}

const nextQuestion = async (questionIndex: number) => {
  // console.log('questionIndex', questionIndex)
  // console.log('nextQuestion')
  if (numberOfQuestion.value == countOfQuestions.value) {
    // console.log('последний вопрос, нужно завершать тест')
    await finishTest()
  } else {
    if (!questionsWithAnswers.value[questionIndex].answer) {
      const message = {
        title: $t('Спочатку оберіть варіант відповіді'),
      }
      useShowModal(message)
    } else {
      numberOfQuestion.value += 1
      // console.log('numberOfQuestion', numberOfQuestion.value)
      if (numberOfQuestion.value == countOfQuestions.value) {
        nextBtnText.value = $t("Завершити тестування")
      }
    }
  }
  // questionsWithAnswers.value[questionIndex].answer = answerId
}

watch(() => numberOfQuestion.value, () => {
  // console.log('изменился номер вопроса')
  if (!numberOfQuestion.value) return
  // console.log('numberOfQuestion.value', numberOfQuestion.value)
  // console.log('questions.value', questions.value)
  currentQuestion.value = questions.value[numberOfQuestion.value - 1]
  // console.log('текущий вопрос', currentQuestion.value)

}, { immediate: true })

onMounted(async () => {
  // console.log('TEST CONTROLLER mounted')
  console.log(props)
  // numberOfQuestion = 'zaz'
  // numberOfQuestion.value = 2
  // console.log('numberOfQuestion', numberOfQuestion)
  await getTestOfUser()
  await getQuestions()
})

const __returned__ = { props, $t, isLoad, result, questions, questionsWithAnswers, answersCount, idTestOfUser, statusOfTest, test, formdata, numberOfQuestion, countOfQuestions, currentQuestion, nextBtnText, testIsFinished, testIsPassed, testResultText, certFileUrl, getTestOfUser, getQuestions, startTest, finishTest, addAnswer, nextQuestion, get useGlobal() { return useGlobal }, ref, onMounted, reactive, watch, get useShowModal() { return useShowModal }, get useErrorsGetter() { return useErrorsGetter }, get useParsedSetter() { return useParsedSetter }, get startTestResource() { return startTestResource }, get finishTestResource() { return finishTestResource }, get testOfUserResource() { return testOfUserResource }, get questionsOfTestResource() { return questionsOfTestResource } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})